import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ORIGINAL_DOMAINS } from '@/lib/routes';

interface Props {
  children: JSX.Element;
}

// https://demo.flyer.us/exam/login?auth_redirect_url=/check-points => https://exam.flyer.us/exam/login?auth_redirect_url=https://demo.flyer.us/exam/check-points&ref=demo.flyer.us

export default function SubDomainAuthRedirection({ children }: Props) {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
      const { hostname } = window.location;

      const authPaths = ['/login', '/signup', '/verify-account'];

      const isOnAuth = authPaths.some((path) => router.pathname.includes(path));

      if (!isOnAuth) {
        return;
      }

      const parts = hostname.split('.');

      if (!ORIGINAL_DOMAINS.includes(parts[0] as string)) {
        const examUsUrl = process.env.NEXT_PUBLIC_EXAM_US_URL as string;

        const redirectUrl = router.query.auth_redirect_url || '/';

        const urlEncode = encodeURIComponent(
          `${window.origin}/exam${redirectUrl as string}&ref=${hostname}`,
        );
        window.location.href = `${examUsUrl}/${router.pathname}?auth_redirect_url=${urlEncode}`;
      }
    }
  }, [router]);

  return children;
}
